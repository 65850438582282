import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Link } from 'gatsby';
import { CodeBlock } from '../../../components/CodeBlock';
import { SEO } from '../../../components/SEO';
import { Layout } from '../../../layouts/Layout';
import { styles as S } from '@ulule/owl-kit-components/dist/next.esm';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Identity > Typography > Families" mdxType="SEO" />
    <h1>{`Families`}</h1>
    <h2>{`Sofia Pro (Primary)`}</h2>
    <CodeBlock preview mdxType="CodeBlock">
      {`<div>
  <p style={{ font: '400 28px sofia-pro', margin: '10px 0' }}>Sofia Pro Regular 400</p>
  <p style={{ font: '500 28px sofia-pro', margin: '10px 0' }}>Sofia Pro Medium 500</p>
  <p style={{ font: '600 28px sofia-pro', margin: '10px 0' }}>Sofia Pro Semi Bold 600</p>
  <p style={{ font: '700 28px sofia-pro', margin: '10px 0' }}>Sofia Pro Bold 700</p>
  <p style={{ font: '900 28px sofia-pro', margin: '10px 0' }}>Sofia Pro Black 900</p>
  <p style={{ font: '900 italic 28px sofia-pro-condensed', margin: '10px 0' }}>Sofia Pro Condensed Black Italic 900i</p>
</div>`}
    </CodeBlock>
    <h2>{`FF Tisa Pro (Secondary)`}</h2>
    <CodeBlock preview mdxType="CodeBlock">
      {`<div>
  <p style={{ font: '400 28px ff-tisa-web-pro', margin: '10px 0' }}>Tisa Pro Regular 400</p>
  <p style={{ font: '600 28px ff-tisa-web-pro', margin: '10px 0' }}>Tisa Pro Medium 600</p>
  <p style={{ font: '700 28px ff-tisa-web-pro', margin: '10px 0' }}>Tisa Pro Bold 700</p>
</div>`}
    </CodeBlock>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      